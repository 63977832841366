import React from 'react';
import { navigate } from 'gatsby';

const Dashboard: React.FC = () => {
  if (typeof window !== 'undefined') {
    navigate('/support');
  }
  return null;
};

export default Dashboard;
